// import { css } from 'styled-components';

// Transition
export function transition(timing = 0.3) {
  return `
      -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `
}

// Border Radius
export function borderRadius(radius = 0) {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `
}

// Box Shadow
export function boxShadow(shadow = 'none') {
  return `
      -webkit-box-shadow: ${shadow};
      -moz-box-shadow: ${shadow};
      box-shadow: ${shadow};
  `
}

export const statusColors = {
  blue: {
    bg: '#d2eef7',
    fg: '#1dacd6',
    chart: '#2cb6ff',
  },
  red: {
    bg: '#fbedeb',
    fg: '#cf513d',
    chart: '#ff6b6b',
  },
  green: {
    fg: '#255f30',
    bg: '#eef6ec',
    chart: '#30cb6f',
  },
  yellow: {
    bg: '#fef3d6',
    fg: '#fac331',
    chart: '#fac331',
  },
}
