const options = [
  {
    key: '',
    label: 'nav.dashboard',
    leftIcon: 'ion-arrow-graph-up-right',
  },
  {
    key: 'search',
    label: 'nav.search',
    leftIcon: 'ion-android-search',
  },
  {
    key: 'project',
    label: 'nav.projects',
    leftIcon: 'ion-android-apps',
  },
  {
    key: 'project-setting',
    label: 'nav.metadata',
    leftIcon: 'ion-android-settings',
    children: [
      {
        key: 'setting-layer-1',
        label: 'nav.metadata.project',
      },
      {
        key: 'setting-layer-2',
        label: 'nav.metadata.strategyPlan',
      },
      {
        key: 'setting-layer-3',
        label: 'nav.metadata.outcomeIndicators',
      },
      {
        key: 'setting-layer-4',
        label: 'nav.metadata.projectDescriptionType',
      },
    ],
  },
  {
    key: 'file-manager',
    label: 'nav.fileExplorer',
    leftIcon: 'ion-android-folder-open',
  },
  {
    key: 'user',
    label: 'nav.users',
    leftIcon: 'ion-ios-contact-outline',
  },
  {
    key: 'role',
    label: 'nav.rolePermission',
    leftIcon: 'ion-android-hand',
  },
  {
    key: 'api-management',
    label: 'API Management',
    leftIcon: 'ion-link',
  },
]
export default options
