import { Directus } from '@directus/sdk'

const getEndpoint = () => {
  return 'https://demo.prome.tinystack.app'
}

export const getApiEndpoint = () => {
  return `http://143.198.205.112`
}

export const END_POINT = getEndpoint()

export const directus = new Directus(getEndpoint(), {
  auth: {
    mode: 'json',
  },
})
