import actions from './actions'

const initState = { idToken: null, loadingLogin: false }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        loadingLogin: false,
        userInfo: action.userInfo,
      }
    case actions.LOGOUT:
      return initState
    case actions.LOADING_LOGIN:
      return { ...state, loadingLogin: true }
    case actions.LOGIN_ERROR:
      return { ...state, loadingLogin: false }
    default:
      return state
  }
}
