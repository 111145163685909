import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import asyncComponent from '../../helpers/AsyncFunc'

const settingRoute = () => {
  let routeArr = []
  for (let index = 1; index <= 12; index++) {
    routeArr.push({
      path: `setting-layer-${index}`,
      component: asyncComponent(() => import(`../Setting/layer${index}`)),
    })
  }
  return routeArr
}

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'user',
    component: asyncComponent(() => import('../AdminUser')),
  },
  {
    path: 'role',
    component: asyncComponent(() => import('../Role')),
  },
  {
    path: 'organization',
    component: asyncComponent(() => import('../Organization')),
  },
  {
    path: 'transport-type',
    component: asyncComponent(() => import('../TransportType')),
  },
  {
    path: 'mission-type',
    component: asyncComponent(() => import('../MissionType')),
  },
  {
    path: 'process-method',
    component: asyncComponent(() => import('../ProcessMethod')),
  },
  {
    path: 'project',
    component: asyncComponent(() => import('../Project')),
  },
  {
    path: 'search',
    component: asyncComponent(() => import('../Search')),
  },
  {
    path: 'project-detail',
    component: asyncComponent(() => import('../Project/projectDetail')),
  },
  {
    path: 'project-detail/sub-project',
    component: asyncComponent(() => import('../Project/projectDetail')),
  },
  {
    path: 'file-manager',
    component: asyncComponent(() => import('../FileManager')),
  },
  {
    path: 'api-management',
    component: asyncComponent(() => import('../APIManagement')),
  },
  {
    path: '1',
    component: asyncComponent(() => import('../BlockD/BlockD1')),
  },
  {
    path: '2',
    component: asyncComponent(() => import('../BlockD/BlockD2')),
  },

  {
    path: '3',
    component: asyncComponent(() => import('../BlockD/BlockD3')),
  },
  {
    path: '4',
    component: asyncComponent(() => import('../BlockD/BlockD4')),
  },

  ...settingRoute(),
]

class AppRouter extends Component {
  render() {
    const { url, style } = this.props
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          )
        })}
      </div>
    )
  }
}

export default AppRouter
