import { directus } from './config'

export const checkAuthenticate = async () => {
  let authenticated = false
  let props = {}

  await directus.auth
    .refresh()
    .then(async (res) => {
      authenticated = true
      const userInfo = await getUser()
      props = { ...res, userInfo }
    })
    .catch((e) => {
      // logout()
    })
  return {
    authenticated,
    ...props,
  }
}

export const login = async ({ email, password }) => {
  const res = await directus.auth
    .login({ email, password })
    .then(async (props) => {
      return {
        sessionToken: props.access_token,
        expireTime: props.expires,
        refreshToken: props.refresh_token,
      }
    })
  return res
}

export const logout = async () => {
  const res = await directus.auth.logout()
  return res
}

export const getUser = async () => {
  const res = await directus.users.me.read({
    fields: [
      '*',
      'customRole.rolePermissions.userRolePermissions_id.name',
      'customRole.*',
      'organizations.*.*',
    ],
  })
  const permissionItems = res?.customRole?.rolePermissions.map(
    (r) => r?.userRolePermissions_id?.name
  )
  const organizations = res?.organizations?.map((r) => ({
    id: r?.metaOrganization_id?.id,
    name: r?.metaOrganization_id?.name,
  }))

  return {
    ...res,
    permissionItems,
    organizations,
  }
}

export const resetPasswordRequest = async (email) => {
  const res = await directus.auth.password.request(
    email,
    'https://localhost:3000/password/reset'
  )
  return res
}
