import {
  CheckOutlined,
  DownOutlined,
  GlobalOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import { Card, Col, Dropdown, Row, Space, Tag, Typography } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import authAction from '../../redux/auth/actions'

const { logout } = authAction

const TopbarLogout = ({ logout, userInfo, isLoading }) => {
  const { t, i18n } = useTranslation()
  const changeLanguageHandler = (val) => {
    i18n.changeLanguage(val)
  }
  const name = `${userInfo?.first_name} ${userInfo?.last_name}`.toUpperCase()
  const items = [
    {
      key: 'user',
      label: (
        <>
          <Row justify="center">
            <Col>
              <Typography.Text strong>{name}</Typography.Text>
            </Col>
          </Row>
          <Row justify="center">
            <Col>{userInfo?.email}</Col>
          </Row>
          <Row justify="center">
            <Col>
              <Tag color={userInfo?.customRole?.tagColor} style={{ margin: 0 }}>
                {userInfo?.customRole?.name}
              </Tag>
            </Col>
          </Row>
          {userInfo?.organizations?.length ? (
            <Row justify="center">
              <div
                style={{
                  maxWidth: '100%',
                  textAlign: 'center',
                  border: '1px dashed rgba(0,0,0,0.2)',
                  borderRadius: '5px',
                  padding: '5px',
                  marginTop: '5px',
                }}
              >
                {userInfo?.organizations?.map((i) => (
                  <div key={i?.name}>{i?.name}</div>
                ))}
              </div>
            </Row>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'lang',
      label: 'Language',
      icon: <GlobalOutlined />,
      children: [
        {
          key: 'th',
          label: <>TH</>,
          icon: i18n.resolvedLanguage === 'th' && <CheckOutlined />,
        },
        {
          key: 'en',
          label: <>EN</>,
          icon: i18n.resolvedLanguage === 'en' && <CheckOutlined />,
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: t('signout'),
      icon: <LogoutOutlined />,
    },
  ]
  const handleMenuClick = (e) => {
    if (e?.keyPath?.length === 2 && e?.keyPath[1] === 'lang') {
      changeLanguageHandler(e?.key)
    } else if (e?.key === 'logout') {
      logout()
    }
  }
  return (
    <Space
      direction="horizontal"
      size="middle"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
      }}
    >
      {!isLoading && (
        <Dropdown menu={{ items, onClick: handleMenuClick }}>
          <Col>
            <Row align="middle" gutter={[8, 0]}>
              <Col>
                <Row>
                  <Typography.Link>{name}</Typography.Link>
                </Row>
              </Col>
              <Col>
                <DownOutlined />
              </Col>
            </Row>
          </Col>
        </Dropdown>
      )}
    </Space>
  )
}
export default connect(
  (state) => ({
    userInfo: state.Auth.userInfo,
    isLoading: state.Auth.loadingLogin,
  }),
  { logout }
)(TopbarLogout)
