import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'

import ResetPassword from './ResetPassword'
import App from './containers/App/App'
import asyncComponent from './helpers/AsyncFunc'
import { apolloClient } from './helpers/directus/gql'

const RestrictedRoute = ({
  component: Component,
  isLoggedIn,
  isLoading,
  ...rest
}) => {
  const renderComponent = (props) => {
    return isLoggedIn || isLoading ? (
      <Component {...props} isLoading={isLoading} />
    ) : (
      <Redirect
        to={{
          pathname: '/signin',
          state: { from: props.location },
        }}
      />
    )
  }
  return <Route {...rest} render={renderComponent} />
}
const PublicRoutes = ({ history, isLoggedIn, isLoading }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <ApolloProvider client={apolloClient}>
          <Route
            exact
            path={'/'}
            component={asyncComponent(() =>
              import('./containers/Authen/signin')
            )}
          />
          <Route
            exact
            path={'/signin'}
            component={asyncComponent(() =>
              import('./containers/Authen/signin')
            )}
          />
          <Route
            exact
            path={'/policy'}
            component={asyncComponent(() => import('./containers/Policy'))}
          />
          <RestrictedRoute
            path="/dashboard"
            component={App}
            isLoggedIn={isLoggedIn}
            isLoading={isLoading}
          />
          <Route
            path="/report"
            component={(props) => <App {...props} hideNav />}
          />
          <Route
            path="/change-password"
            component={(props) => <ResetPassword {...props} />}
          />
        </ApolloProvider>
      </div>
    </ConnectedRouter>
  )
}

export default connect((state) => ({
  isLoggedIn: state.Auth.idToken !== null,
  isLoading: state.Auth.loadingLogin,
}))(PublicRoutes)
