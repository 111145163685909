import { Button, ConfigProvider, Tooltip } from 'antd'
import enLocale from 'antd/es/locale/en_US'
import thLocale from 'antd/es/locale/th_TH'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import DashAppHolder from './dashApp.style'
import Boot from './redux/boot'
import { history, store } from './redux/store'
import PublicRoutes from './router'
import { themeConfig } from './settings'
import './settings/i18n'
import themes from './settings/themes'

dayjs.locale('th')
dayjs.extend(buddhistEra)

const DashApp = (props) => {
  dayjs.locale(props?.i18n?.language)
  const [locale, setLocale] = useState(
    props?.i18n?.language === 'th' ? thLocale : enLocale
  )
  useEffect(() => {
    setLocale(props?.i18n?.language === 'th' ? thLocale : enLocale)
  }, [props?.i18n?.language])

  const customLocale = useMemo(() => {
    let temp = locale
    const custom = {
      yearFormat: 'BBBB',
      dateFormat: 'M/D/BBBB',
      dayFormat: 'D',
      dateTimeFormat: 'M/D/BBBB HH:mm:ss',
      monthFormat: 'MMMM',
    }
    temp = {
      ...temp,
      DatePicker: {
        ...temp.DatePicker,
        lang: { ...temp?.DatePicker?.lang, ...custom },
      },
      Calendar: {
        ...temp.Calendar,
        lang: { ...temp?.Calendar?.lang, ...custom },
      },
    }
    return temp
  }, [locale])
  return (
    <ConfigProvider locale={customLocale}>
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <DashAppHolder>
          <Provider store={store}>
            <PublicRoutes history={history} />
          </Provider>
          {/* <div className="floating">
            <Tooltip title="Help Desk" placement="left">
              <a
                className="floating_icon"
                href="https://line.me/R/nv/chat"
                target="_blank"
              />
            </Tooltip>
          </div> */}
        </DashAppHolder>
      </ThemeProvider>
    </ConfigProvider>
  )
}
Boot()
  .then(() => DashApp())
  .catch((error) => console.error(error))

export default withTranslation()(DashApp)
