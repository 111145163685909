import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import lineIcon from './assets/images/line_icon.jpeg'

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .ant-row {
    ${
      '' /* margin-left: -8px;
    margin-right: -8px; */
    };
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .ant-input-masked {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    &:focus {
      border-color: #4096ff;
      box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
      border-inline-end-width: 1px;
      outline: 0;
    }
  }
  .floating {
    position: absolute;
    bottom: 0;
    right: 0;
    &_icon {
      width: 45px;
      height: 45px;
      background-color: #05c655;
      border-radius: 50%;
      margin-right: 10px;
      margin-bottom: 10px;
      background-position: center;
      background-size: 35px;
      background-repeat: no-repeat;
      background-image: url(${lineIcon});
      box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
        rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
      display: block;
      cursor: pointer;
      transition: 0.05s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .deleteBtn {
    color: ${palette('error', 0)};
    cursor: pointer;
    font-size: 18px;
    &:hover {
      color: ${palette('error', 2)};
    }
  }
`

export default DashAppHolder
