const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  JWT_LOGIN_REQUEST: 'JWT_LOGIN_REQUEST',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOADING_LOGIN: 'LOADING_LOGIN',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: () => ({
    type: actions.LOGIN_REQUEST,
  }),
  jwtLogin: (history, userInfo) => ({
    type: actions.JWT_LOGIN_REQUEST,
    payload: { history, userInfo },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
}
export default actions
