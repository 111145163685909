import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
} from '@apollo/client'

import { getToken } from '../../utility'
import { END_POINT } from '../config'

const getConfig = () => {
  const params = new URLSearchParams(window.location?.search)
  const token = localStorage.getItem('token') || params.get('token')

  return {
    uri: `${END_POINT}graphql?access_token=${token}`,
    cache: new InMemoryCache(),
  }
}
export const apolloClient = new ApolloClient(getConfig())
