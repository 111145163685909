import { push } from 'react-router-redux'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import { notification } from '../../components'
import { checkAuthenticate, login } from '../../helpers/directus/auth'
// import { login } from '../../helpers/parseHelper'
import { clearToken } from '../../helpers/utility'
import actions from './actions'

const fakeApiCall = false // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* () {
    if (fakeApiCall) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: 'secret token',
      })
    } else {
      const username = localStorage.getItem('username')
      const password = localStorage.getItem('password')
      //yield put({ type: actions.LOGIN_ERROR });
    }
  })
}

export function* jwtLoginRequest() {
  yield takeEvery(actions.JWT_LOGIN_REQUEST, function* ({ payload }) {
    yield put({
      type: actions.LOADING_LOGIN,
    })
    try {
      const result = yield call(login, payload.userInfo)
      if (result.error) {
        notification('error', result.error)
        yield put({
          type: actions.LOGIN_ERROR,
        })
      } else {
        yield put({
          type: actions.CHECK_AUTHORIZATION,
        })
        yield put(push('/dashboard'))
      }
    } catch (error) {
      notification('error', error.message)
      yield put({
        type: actions.LOGIN_ERROR,
      })
    }
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('token', payload.token)
    yield localStorage.setItem('expireTime', payload.expireTime)
  })
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {})
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken()
    yield put(push('/'))
  })
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    yield put({
      type: actions.LOADING_LOGIN,
    })
    const res = yield call(checkAuthenticate)
    if (res.authenticated) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: res.access_token,
        expireTime: res.expires,
        refreshToken: res.refresh_token,
        userInfo: res?.userInfo,
      })
    } else {
      yield put({
        type: actions.LOGIN_ERROR,
      })
      clearToken()
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(jwtLoginRequest),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ])
}
