import packageJson from '../package.json'

const env = {}
const PROD = true
const URL = PROD ? 'https://api.mot.tinystack.app' : 'http://localhost'
const PORT = 1337
env.url = URL
env.port = PORT
env.parseServerUrl = `${URL}:${PORT}/parse`
env.appId = '97dd70d3-da0a-432a-9dc9-01ef5a06c6a9'
env.masterKey = '597e1c2d-21f6-40d4-8748-29a5f2f30f98'
env.javascriptKey = 'bdbd0236-bc83-457d-a5b0-08a273859dbb'
env.appName = 'Project Monitor'
env.version = packageJson.version
export default env
