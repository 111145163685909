import { Layout, Spin } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Debounce } from 'react-throttle'
import WindowResizeListener from 'react-window-size-listener'
import { ThemeProvider } from 'styled-components'

import Box from '../../components/utility/box'
import appActions from '../../redux/app/actions'
import authAction from '../../redux/auth/actions'
import { siteConfig } from '../../settings'
import { themeConfig } from '../../settings'
import themes from '../../settings/themes'
import Sidebar from '../Sidebar/Sidebar'
import Topbar from '../Topbar/Topbar'
import AppRouter from './AppRouter'
import AppHolder from './commonStyle'
import './global.css'

const { Content, Footer } = Layout
const { logout } = authAction
const { toggleAll } = appActions
export class App extends Component {
  renderComponent = (url) => {
    return this.props.isLoading ? (
      <Content
        className="isomorphicContent"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '30vh',
        }}
      >
        <Spin tip="Loading..." size="large" data-testid="loading" />
      </Content>
    ) : (
      <AppRouter url={url} />
    )
  }
  render() {
    const { url } = this.props.match
    const { height, hideNav = false } = this.props
    const appHeight = window.innerHeight
    return (
      <>
        {hideNav ? (
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <AppHolder>
              <Debounce time="1000" handler="onResize">
                <WindowResizeListener
                  onResize={(windowSize) =>
                    this.props.toggleAll(
                      windowSize.windowWidth,
                      windowSize.windowHeight
                    )
                  }
                />
              </Debounce>
              <Box
                style={{
                  backgroundColor: 'rgb(241 243 247)',
                  minHeight: '100vh',
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    flexShrink: '0',
                    position: 'relative',
                  }}
                >
                  {this.renderComponent(url)}
                </Content>
              </Box>
            </AppHolder>
          </ThemeProvider>
        ) : (
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: '70px 0 0',
                        flexShrink: '0',
                        background: '#f1f3f6',
                        position: 'relative',
                      }}
                    >
                      {this.renderComponent(url)}
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed',
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        )}
      </>
    )
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    height: state.App.height,
  }),
  { logout, toggleAll }
)(App)
