import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Tag,
  Typography,
} from 'antd'
import axios from 'axios'
import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { END_POINT } from './helpers/directus/config'
import logo from './image/logo.jpeg'

const ResetPassword = (props) => {
  const [formRef] = Form.useForm()
  const parsed = queryString.parse(props.location.search)
  const token = useMemo(() => parsed?.token, [parsed])
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const requestResetPassword = async () => {
    setIsError(false)
    setIsSuccess(false)
    const values = await formRef.validateFields()
    if (values) {
      setLoading(true)
      try {
        const res = await axios.post(`${END_POINT}auth/password/reset`, {
          token,
          password: values.password,
        })
        setIsSuccess(true)
      } catch (e) {
        setIsError(true)
      }
      setLoading(false)
    }
  }

  const goToLogin = () => {
    props.history.push('/')
  }
  return (
    <Wrapper>
      {isSuccess ? (
        <Card>
          <Row justify="center">
            <CheckCircleFilled
              style={{ color: 'green', fontSize: '5rem', marginBottom: '1rem' }}
            />
          </Row>
          <Row justify="center">
            <Typography.Title level={4}>ตั้งค่ารหัสผ่านสำเร็จ</Typography.Title>
          </Row>
          <Row justify="center">
            <Button
              type="primary"
              onClick={goToLogin}
              style={{ width: '100%', marginTop: '20px' }}
              loading={loading}
            >
              ลงชื่อเข้าใช้
            </Button>
          </Row>
        </Card>
      ) : token ? (
        <Card>
          <Row justify="center">
            <Image src={logo} alt="" preview={false} width={120} />
          </Row>
          <Row justify="center">
            <Typography.Title level={4}>ตั้งค่ารหัสผ่าน</Typography.Title>
          </Row>
          <Row justify="center" align="middle">
            <Col>
              <Form form={formRef} layout="vertical">
                <Form.Item
                  name="password"
                  label="รหัสผ่านใหม่"
                  rules={[{ required: true }, { min: 8 }]}
                  help="รหัสผ่านต้องมีความยาวไม่ต่ำกว่า 8 ตัวอักษร"
                >
                  <Input.Password />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {isError ? (
            <Row>
              <Tag
                icon={<CloseCircleOutlined />}
                color="error"
                style={{
                  width: '100%',
                  height: 50,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                ตั้งค่าหรัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง
              </Tag>
            </Row>
          ) : (
            <></>
          )}
          <Row justify="center">
            <Button
              type="primary"
              onClick={requestResetPassword}
              style={{ width: '100%', marginTop: '20px' }}
              loading={loading}
            >
              ยืนยัน
            </Button>
          </Row>
        </Card>
      ) : (
        <Card>
          <Row justify="center">
            <CloseCircleFilled
              style={{ color: 'red', fontSize: '5rem', marginBottom: '1rem' }}
            />
          </Row>
          <Row justify="center">
            <Typography.Title level={4}>เกิดข้อผิดพลาด</Typography.Title>
          </Row>
        </Card>
      )}
    </Wrapper>
  )
}

export default ResetPassword
const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
